.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: var(--bg-color);
    display: flex;
    min-width: 20rem;
}

.popup-body {
    flex: 1;
}

.popup-title {
    font-size: 1.2rem;
    color: var(--dark-fg-color);
    border-bottom: 1px solid var(--border-color);
}

.popup-label {
    font-size: xx-large;
}

.popup-download {
    font-size: 1rem;
    color: var(--dark-fg-color);    
}

.popup-edit {
    font-size: xx-large;
    max-width: 20rem;
}

.popup-textarea {
    font-size: x-large;
    max-width: 20rem;
    min-height: 20rem;
}
