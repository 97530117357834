:root {
    --fg-color: #dddddd;
    --dark-fg-color: #999;
    --light-fg-color: #eeeeee;
    --alt-fg-color: #666;
    --bg-color: #222222;
    --alt-bg-color: #1d1d1d;
    --dark-bg-color: #181818;
    --light-bg-color: #3e3e3e;
    --padding: 4px;
    --sm-padding: 0.2rem;
    --lg-padding: 0.6rem;
    --xl-padding: 1.2rem;
    --border-color: var(--light-bg-color);
    --border-dark-color: #333;
    --border-radius: 0.3rem;
    --border-width: 1px;
    --color-green: #009944;
    --color-red: #993333;
    --color-blue: #0088aa;
    --color-orange: #96791c;
    --color-gray: #3e3e3e;
    --color-cyan: #00ccff;
    --button-bg-color: var(--color-green);
}

body {
    font: 14px "Century Gothic", Futura, sans-serif;
    height: 100%;
    margin: 0px;
    background-color: var(--bg-color);
    color: var(--fg-color);
}

::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}
::-webkit-scrollbar-track {
    background: var(--dark-bg-color);
}
::-webkit-scrollbar-thumb {
    background: var(--light-bg-color);
}

.app {
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.app-content {
    flex: 1;
    overflow: auto;
}

.content {
    padding: var(--xl-padding);
}

table {
    border: 1px solid #666;
    border-spacing: 0;
    border-collapse: collapse;
}

/*
tr:nth-child(2n) {
    background-color: var(--alt-bg-color);
}
*/

th {
    font-size: 1.2rem;
    color: #666;
    font-weight: 300;
    background-color: var(--alt-bg-color);
}

td,
th {
    width: 12rem;
    padding: var(--padding);
    border: 1px solid #444;
    text-align: center;
}

input[type="text"] {
    background-color: var(--alt-bg-color);
    border: 1px solid var(--border-color);
    color: var(--dark-fg-color);
}

input[type="text"]:focus-visible {
    outline: none;
    border-color: var(--color-blue);
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.margin-left {
    margin-left: var(--lg-padding);
}

.button,
.button-disabled,
.button-small {
    color: var(--fg-color);
    text-decoration: none;
    border: var(--border-width) solid var(--button-bg-color);
    border-radius: var(--border-radius);
    background-color: var(--button-bg-color);
    padding: var(--padding);
    margin: var(--padding);
    cursor: pointer;
    transition: filter 0.1s;
}

.button-small {
    margin: 0rem;
    padding: 1px 6px;
}

.button:hover {
    filter: brightness(1.5);
}

.button-disabled {
    background-color: var(--alt-bg-color);
    color: var(--dark-fg-color);
    border-color: var(--border-color);
    cursor: default;
    transition: none;
}
.button-disabled:hover {
    filter: none;
}

.button-green {
    background-color: var(--color-green);
    border: 1px solid var(--color-green);
}

.button-red {
    background-color: var(--color-red);
    border: 1px solid var(--color-red);
}

.button-blue {
    background-color: var(--color-blue);
    border: 1px solid var(--color-blue);
}

.button-orange {
    background-color: var(--color-orange);
    border: 1px solid var(--color-orange);
}

.button-gray {
    background-color: var(--color-gray);
    border: 1px solid var(--color-gray);
}

.button-cyan {
    background-color: var(--color-cyan);
    border: 1px solid var(--color-cyan);
}

.min-width {
    min-width: 6rem;
}

.fg {
    color: var(--fg-color);
}

.fg-dark {
    color: var(--dark-fg-color);
}

.fg-alt {
    color: var(--alt-fg-color);
}

.bg-green {
    background-color: var(--color-green);
}

.bg-red {
    background-color: var(--color-red);
}

.bg-blue {
    background-color: var(--color-blue);
}

.bg-orange {
    background-color: var(--color-orange);
}

.bg-gray {
    background-color: var(--light-bg-color);
}

.bg-cyan {
    background-color: var(--color-cyan);
}

.border-green {
    border: 1px solid var(--color-green);
}

.border-red {
    border: 1px solid var(--color-red);
}

.border-blue {
    border: 1px solid var(--color-blue);
}

.border-orange {
    border: 1px solid var(--color-orange);
}

.border-gray {
    border: 1px solid var(--light-bg-color);
}

.border-cyan {
    border: 1px solid var(--color-cyan);
}

.flex-row {
    display: flex;
}

.flex-row-wrap {
    display: flex;
    flex-flow: row wrap;
}

.flex-col {
    display: flex;
    flex-flow: column;
}

.flex-grow {
    flex: 1;
}

.flex-right {
    justify-content: flex-end;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.full-width {
    width: 100%;
}

.show-on-hover-target {
    opacity: 0;
    transition: opacity 0.1s;
}

.show-on-hover:hover > .show-on-hover-target {
    opacity: 1;
}

.border-first {
    border: 1px solid var(--border-color);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.border-last {
    border: 1px solid var(--border-color);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.square {
    width: 1rem;
    display: inline-block;
    margin: var(--padding);
}

.padding-content {
    padding: var(--xl-padding);
}

.pointer {
    cursor: pointer;
}
